<template>
    <div>
        <Logout v-if="showLogout" @closeLogout="showLogout = false"/>
        <!-- <PassChange v-if="passMoadlStatus" @closePassChange="passMoadlStatus = false" @openWithdrawal="withdrawalStatus=true" /> -->
        <!-- <Push v-if="pushModalStatus" @closePush="pushModalStatus = false"/> -->
        <!-- <Withdrawal v-if="withdrawalStatus" @closeWithdrawal="withdrawalStatus=false"/> -->
        <Profile v-if="settingStatus" :checkMobile="checkMobile" @closeProfile="settingStatus = false"/>
        <div class="toggle_menu" id="toggle_menu">
            <div class="toggle_box">
                <div class="toggle_close" @click.once="$emit('closeAside')">
                    <h2>메뉴</h2>
                    <img src="/media/images/icon_close_24.svg">
                </div>
                <div class="toggle_email">
                    <h2 style="cursor:default;"> {{ email }}</h2>
                    <img src="/media/img/toggle_setting.svg" width="24px" height="24px" @click="showSettingBox" class="show_select_box"/>
                </div>
                <ul>
                    <li @click="selectMySpace">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon1.svg" alt=""/>
                        </div>
                        <p>마이스페이스</p>
                    </li>
                    <li @click="selectBoardcastMusic" v-if="this.$store.state.division === 'Building'">
                        <div class="toggle_icon_box">
                            <img src="/media/img/icon_radiomusic_24.svg" width="24px" height="24px" alt=""/>
                        </div>
                        <p>홍보/방송 음원 관리</p>
                    </li>
                    <li @click="selectFAQ">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon6.svg" alt="" />
                        </div>
                        <p>고객센터</p>
                    </li>
                </ul>
                <div>
                    <p class="toggle_policy" @click="selectService">이용약관</p>
                    <p class="toggle_policy" @click="selectprivacy">개인정보처리방침</p>
                </div>
                <div class="logout">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33317 13.4173C1.15636 13.4173 0.98679 13.3471 0.861766 13.2221C0.736742 13.097 0.666504 12.9275 0.666504 12.7507V0.750651C0.666504 0.57384 0.736742 0.404271 0.861766 0.279246C0.98679 0.154222 1.15636 0.0839844 1.33317 0.0839844H10.6665C10.8433 0.0839844 11.0129 0.154222 11.1379 0.279246C11.2629 0.404271 11.3332 0.57384 11.3332 0.750651V2.75065H9.99984V1.41732H1.99984V12.084H9.99984V10.7507H11.3332V12.7507C11.3332 12.9275 11.2629 13.097 11.1379 13.2221C11.0129 13.3471 10.8433 13.4173 10.6665 13.4173H1.33317ZM9.99984 9.41732V7.41732H5.33317V6.08398H9.99984V4.08398L13.3332 6.75065L9.99984 9.41732Z" fill="white"/>
                    </svg>
                    <p @click="showLogout = true">로그아웃</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Logout from "@/components/modal/Logout/Logout.vue";
// import Withdrawal from "@/components/modal/Profile/Withdrawal.vue";
// import PassChange from "@/components/modal/Profile/PassChange.vue";
// import Push from "@/components/modal/Profile/Push.vue";
import Profile from "@/components/modal/Profile/Profile.vue";
import VueCookies from "vue-cookies";
import router from "@/router";
import $ from 'jquery';

export default defineComponent({
  components: {
    Logout,
    // PassChange,
    // Withdrawal,
    // Push,
    Profile
  },
  data () {
    return {
      showLogout: false,
      email: VueCookies.get("email"),
      passMoadlStatus: false,
      pushModalStatus: false,
      withdrawalStatus: false,
      settingStatus: false,
      mode: ''
    };
  },
  created () {
    this.modeCheck();
  },
  mounted () {
    this.set();
  },
  methods: {
    modeCheck () { // 개발 운영 구분 로직
      var url = window.location.host;
      url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? this.mode = 'dev' : this.mode = 'prod';
    },
    set () { // 숨기기
      $(".select_show_box").hide();
    },
    showSettingBox () { // 설정 팝업
      this.settingStatus = true;
    },
    selectMySpace () { // 마이스페이스 이동
      router.push({ name: 'MySpace' });
      this.$emit('closeAside');
    },
    selectBoardcastMusic () { // 홍보/방송 음원 관리 이동
      router.push({ name: 'BoardcastMusic' });
      this.$emit('closeAside');
    },
    selectFAQ () { // 고객센터 이동
      router.push({ name: 'FAQ' });
      this.$emit('closeAside');
    },
    selectService () { // 이용약관 이동
      router.push({ name: 'Service' });
      this.$emit('closeAside');
    },
    selectprivacy () { // 개인정보처리방침 이동
      router.push({ name: 'Privacy' });
      this.$emit('closeAside');
    },
    selectPush () { // 푸쉬 알람 이동
      this.pushModalStatus = true;
      this.$emit('closeAside');
    },
    selectPassChange () { // 비밀번호 변경
      this.passMoadlStatus = true;
      this.$emit('closeAside');
    }
  },
  setup () {}
});
</script>

<style scoped>
  .disabled-img { opacity: 0.2 !important; cursor: auto; }
  .disabled-text { color: rgba(255, 255, 255, 0.3) !important;cursor: auto; }
  .logout p { margin: 0; }
  .show_select_box { cursor: pointer; }
  .select_show_box { position:absolute; top:120%; right:14%; padding:6px 0; background: #313131; border: 1px solid rgba(255, 255, 255, 0.08); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); border-radius: 8px; }
  .select_show_box p { display:flex; justify-content:flex-start; align-items:center; padding: 5px 10px; color:#fff; font-size: 12px; font-weight:400; white-space:nowrap; cursor:pointer; margin: 0; }
  .select_show_box p:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .select_show_box p svg {
    margin-right:6px;
  }
  /*toggle_menu*/
  .toggle_menu {position:relative; position:fixed; top:0; right: 0; z-index:100;width:347px; height:100%;background:#151515; filter: drop-shadow(-20px 0px 30px rgba(0, 0, 0, 0.40)); padding:80px  0 0 80px;transition:all 0.3s;}
  .toggle_menu.active {right:0; }
  .toggle_close {position:absolute; top:30px; right:80px;display:flex; padding:env(safe-area-inset-top) 5%;justify-content:center!important; align-items:center;width: 40px; height:40px; border-radius:50%;background: transparent; cursor:pointer;}
  .toggle_close:hover {background: rgba(255, 255, 255, 0.08);}
  .toggle_close:active {background: rgba(255, 255, 255, 0.16);}
  .toggle_close h2 {display:none;}
  .toggle_email {width:100%; display:flex; justify-content:flex-start; align-items:Center;}
  .toggle_email h2 {color: rgba(255, 255, 255, 0.87);font-size:14px; font-weight: 600; cursor:pointer; margin: 0; letter-spacing: 0;}
  .toggle_email img {margin-left: 10px; width: 18px; height: 18px;}

  .toggle_box ul {margin:63px 0 52px; padding: 0;}
  .toggle_box ul .toggle_icon_box {width:22px; margin-right:13px;}
  .toggle_box ul li {display:flex; justify-content:flex-start; align-items:center;margin-bottom:24px; cursor:pointer;}
  .toggle_box ul li:last-child {margin:0;}
  .toggle_box ul li p {font-size:18px;color: rgba(255, 255, 255, 0.87);letter-spacing: -0.5px; }

  .toggle_box > div:nth-child(4) {cursor:pointer;margin-bottom:52px; position: absolute; bottom: 82px; }
  .toggle_box > div:nth-child(4) p {color: rgba(255, 255, 255, 0.6);font-size:16px;cursor:pointer; }
  .toggle_box > div:nth-child(4) p:nth-child(1) {margin-bottom:16px;}
  .toggle_box > div:nth-child(5) {display:flex; align-items:center;cursor:pointer;margin-bottom: 24px; position: absolute; bottom: 24px; }
  .toggle_box > div:nth-child(5) svg {margin-right:12px;}
  .toggle_box > div:nth-child(5) p {text-decoration: none; color: rgba(255, 255, 255, 0.87); font-size:14px;}

  ul li p {
    margin-bottom: 0;
  }
  @media all and (max-width: 1023px) {
    /*toggle_menu*/
    .toggle_menu {right:0%;width:100%; padding:12px 0; filter:none;}
    .toggle_close {position:static; width:100%;display:flex;padding:env(safe-area-inset-top) 5%; justify-content:space-between !important; align-items:Center; margin-bottom:20px;background:transparent;}
    .toggle_close:hover {background:transparent;}
    .toggle_close:active {background:transparent;}
    .toggle_close h2 {display:block; color:#fff; font-size:18px; font-weight:500;}
    .toggle_email {width:100%; padding:12px 5% 23px; display:flex; justify-content:space-between; align-items:Center;}
    .toggle_email h2 {color:#fff; font-size:18px; font-weight:500;}
    .toggle_email img {display:block;}
    .toggle_box ul {display:grid; grid-template-columns: 1fr 1fr 1fr; border-top:1px solid rgba(255,255,255,0.2);border-bottom:1px solid rgba(255,255,255,0.2); padding:16px 5%; margin:0 0 30px;}
    .toggle_box ul li {flex-direction:column; justify-content:center; height:83px;margin-bottom:0;position: relative;}
    .toggle_box ul li .toggle_icon_box {width:24px; margin:0 auto; margin-bottom:6px;}
    .toggle_box ul li .toggle_icon_box img {width:100%; height: 24px;}
    .toggle_box ul li p {font-size:14px; text-align:center;}
    .toggle_box > div:nth-child(4) {padding:0 5%;}
    .toggle_box > div:nth-child(4) p {font-size:16px;}
    .toggle_box > div:nth-child(4) p:nth-child(1) {margin-bottom:14px;}
    .toggle_box > div:nth-child(5) {position:absolute; bottom:50px; left:5%;}
    .toggle_box > div:nth-child(5) p {text-decoration: none;}
    .logout p {
        margin-bottom: 0px;
      }
    .toggle_email img {margin-left: 10px; width: 24px; height: 24px;}
    .toggle_box .toggle_policy { font-size: 14px !important; }
  }
  @media all and (min-width: 1025px) {
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      .toggle_close {
          position: absolute;
          top: 30px;
          right: 80px;
          padding: 13px;
          display: flex;
          justify-content: center!important;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: transparent;
      }
    }
  }
</style>
