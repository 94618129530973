<template>
    <div>
        <Logout v-if="showLogout" @closeLogout="showLogout = false" />
        <header v-if="$store.getters.isKisok" class="kisok_header">
            <div class="kisok_header_container">
                <div class="logo" @click="goPlayer"><img class="kisok_header_img" src="/media/img/logo_white.svg" width="120"
                                                         height="40" alt="">
                </div>
                <div class="kisok_right_menu">
                    <div class="kisok_shop_info">
                        <span class="img_container img_container_init">
                            <img class="icon_img_normal" src="/media/img/header_shop.png" alt="">
                            <img class="icon_img_big" src="/media/img/big_header_shop.png" alt="">
                        </span>

                        <p class="kisok_space_name">{{ $store.state.spaceNm }}</p>

                        <span class="img_container auto_play_icon" @click="selectAutoMusicSetting">
                            <img class="icon_img_normal" src="/media/img/kisok/auto_play.png" alt="">
                            <img class="icon_img_big auto_play_img" src="/media/img/kisok/auto_play.png" alt="">
                        </span>
                    </div>
                </div>
            </div>

        </header>
        <div class="aplayz_header header_tutorial"
             :class="{ 'dim': $store.getters['player/getTutorial'].step1 || $store.getters['player/getTutorial'].step2 }" v-else>
            <div class="player_pc">
                <Aside v-if="showAside" @closeAside="showAside = false" v-click-outside="onClickOutside" />
                <div class="header-trial" v-if="this.$store.state.spacePayAt === 'T'">
                    <p class="header-trial-txt" v-if="DDay === 'day'">[D-{{ DDay }}] 무료 체험 마지막 날입니다. 지금 바로 구독하고 공간에 어울리는 음악을 매일
                        감상해보세요!</p>
                    <p class="header-trial-txt" v-else>[D-{{ DDay }}] 무료 체험이 {{ DDay }}일 남았습니다. 지금 바로 구독하고 공간에 어울리는 음악을 매일 감상해보세요!
                    </p>
                    <div class="header-trial-btn">
                        <button @click="linkPayment()">구독하기</button>
                    </div>
                </div>
                <div class="header" style="background: transparent;backdrop-filter:none;"
                     :style="this.$store.state.spacePayAt === 'T' ? 'top: 52px !important' : '0'">
                    <div class="header_box">
                        <div class="logo aplayz_logo">

                            <img src="/media/img/logo_white.svg" width="120" height="40" alt="" />

                        </div>
                        <div class="right_menu">
                            <div class="shop_info" v-if="$store.state.spaceNm != null">
                                <img src="/media/img/header_shop.png" alt="" />
                                <p style="margin-bottom: 0px" v-html="$store.state.spaceNm" />
                            </div>
                            <div v-if="!isUseKisok" class="alarm_box">
                                <router-link :to="{
                                    name: 'Notice0',
                                }">
                                    <img src="/media/img/alarm.png" v-if="$store.state.noticeCount === 0" alt="" />
                                    <img src="/media/img/alarm_icon_active.svg" v-else alt="" />
                                </router-link>
                            </div>
                            <div class="toggle" @click="showAside = true">
                                <img src="/media/img/toggle.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import Aside from "@/layout/aside/Aside.vue";
import vClickOutside from 'v-click-outside';
import VueCookies from "vue-cookies";
import router from "@/router";
import dayjs from "dayjs";
import Logout from "@/components/modal/Logout/Logout.vue";
export default defineComponent({
  name: "HeaderPlayer",
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Aside,
    Logout
  },
  props: {
    expireDate: String,
    spacePayAt: String
  },
  data () {
    return {
      showLogout: false,
      showAside: false,
      userStatus: true,
      DDay: null
    };
  },
  watch: {
    expireDate (val) {
      this.endDay(val) < 1 ? this.DDay = "day" : this.DDay = this.endDay(val);
    }
  },
  mounted () {
    this.headerScroll();
    window.addEventListener("scroll", this.headerScroll);
  },
  methods: {
    headerScroll () {
      const pageOffsetY = window.pageYOffset;
      const headerEle = document.querySelector(".kisok_header");
      if (headerEle) {
        if (pageOffsetY > 1278) {
          headerEle.classList.add("active");
        } else {
          headerEle.classList.remove("active");
        }
      }
    },
    endDay (value) {
      const today = dayjs();
      const expireDate = dayjs(value);
      const endDay = expireDate.diff(today, "day", true);
      return Math.floor(endDay);
    },
    linkPayment () {
      localStorage.setItem("spaceId", VueCookies.get("spaceId"));
      router.push({ name: "SpacePayment" });
    },
    onClickOutside (event) {
      this.showAside = false;
    },
    selectAutoMusicSetting () {
      this.$router.push({ name: 'AutoPlay' }).catch(() => { });
    },
    goPlayer () {
      this.$router.push({ name: "playerZero" }).catch(() => { });
    }
  },
  computed: {
    isChristmasSeason () {
      const curDate = new Date().getTime();
      const christmasSeason = new Date('2023-12-31 23:59:59').getTime();

      if (curDate > christmasSeason) {
        return false;
      }

      return true;
    },
    isUseKisok () {
      const userId = VueCookies.get("userId");
      const limitList = ["user_004699", "user_000236"];

      const findIdx = limitList.findIndex(item => item === userId);
      if (findIdx > -1) {
        return true;
      }

      return false;
    }
  },
  destroyed () {
    window.removeEventListener("scroll", this.headerScroll);
  }
});
</script>

<style scoped>
.aplayz_logo {
  position: relative;
}

.santa {
  position: absolute;
  top: -3px;
  left: -25px;
  transform: rotate(-28deg);
}

.header_tutorial {
  z-index: 2;
  position: relative;
}

.header_tutorial.dim {
  z-index: 1;
}

.header-trial {
  position: fixed;
  height: 52px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  background: #FB3B69;
  z-index: 10;
}

.header-trial-txt {
  color: #fff;
}

.header-trial-btn {
  margin-left: 16px;
  background: #fff;
  color: #FB3B69;
  border-radius: 5px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 15px;
  height: 36px;
  line-height: 36px;
}

.kisok_header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 55;
  font-weight: 500;
  height: 80px;
  display: flex;
  align-items: center;
}

.kisok_header.active {
  background: rgba(0, 0, 0, .6);
  -webkit-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.kisok_header_container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kisok_header_img {
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.kisok_shop_info {
  display: flex;
  margin-left: 60px;
}

.img_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.img_container_init {
  cursor: inherit;
}

.auto_play_icon {
  margin-left: 25px;
}

.logout_icon {
  margin-left: 25px;
}

.kisok_shop_info .icon_img_normal {
  width: 18px;
  height: 19px;
  cursor: pointer;
  display: inline-block;
}

.kisok_shop_info .icon_img_big {
  display: none;
}

.kisok_shop_info .kisok_space_name {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
  line-height: 1.6;
  text-shadow: 0 0 4px rgba(0, 0, 0, .25);
}

@media all and (min-width: 1660px) {
  .kisok_header_img {
    width: 168px;
    height: 56px;
  }

  .kisok_shop_info .icon_img_normal {
    display: none;
  }

  .icon_img_big {
    display: inline-block !important;
    width: 40px;
    height: 40px;
  }

  .icon_img_big.auto_play_img {
    width: 48px;
    height: 48px;
  }

  .kisok_shop_info .kisok_space_name {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
  }
}
</style>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
