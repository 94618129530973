<template>
    <div>
        <APLAYZHeader v-if="fromMobile && isSeondong" />
        <div>
            <router-view />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import APLAYZHeader from "@/layout/header/Header.vue";

export default defineComponent({
  name: "Layout",
  components: {
    APLAYZHeader
  },
  data () {
    return {

    };
  },
  computed: {
    fromMobile () {
      return this.$store.getters.gettersFromMobile;
    },
    isSeondong () {
      const route = this.$cookies.get("route");
      // const find = this.$store.getters["seongdongplayer/getSeongdongLimitList"].find(item => item === email);
      return route !== 'seongdong';
    }
  }
});
</script>
